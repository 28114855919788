export const adminLteConf = {
    sidebarLeftMenu: [
        {
            label: 'Menú de navegación ',
            separator: true
        },
        {
            label: ' Inicio', 
            route: '/',
            iconClasses: 'fa fa-home ', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ]
        },
        { 
            label: 'Comités Vecinales', 
            route: '/comite', 
            iconClasses: 'fa fa-id-badge', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: ' Usuarios', 
            route: '/usuarios', 
            iconClasses: 'fa fa-user-o', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
      
        { 
            label: 'Registros', 
            route: '/diezD', 
            iconClasses: 'fa fa-users', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'Call Center', 
            route: '/callCenter', 
            iconClasses: 'fa fa-phone-square', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'Usuarios App', 
            route: '/usuariosA', 
            iconClasses: 'fa fa-mobile', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'Registros', 
            route: '/registrosU', 
            iconClasses: 'fa fa-book', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'Bingo', 
            route: '/bingo', 
            iconClasses: 'fa fa-table', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'Analítica', 
            route: '/analitica', 
            iconClasses: 'fa fa-bar-chart', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'Cartas', 
            route: '/carta', 
            iconClasses: 'fa fa-envelope-o', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'Analítica Bingo', 
            route: '/bingoanalitica', 
            iconClasses: 'fa fa-pie-chart', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'Casillas', 
            route: '/casillas', 
            iconClasses: 'fa fa-archive', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'PREP', 
            route: '/prep', 
            iconClasses: 'fa fa-th-list', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'Eventos', 
            route: '/eventos', 
            iconClasses: 'fa fa-calendar-o', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'Encuestas', 
            route: '/registroseventos', 
            iconClasses: 'fa fa-th-list', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'Registros', 
            route: '/registrosfuncionarios', 
            iconClasses: 'fa fa-th-list', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'Asistencias', 
            route: '/asistencias', 
            iconClasses: 'fa fa-list-ol', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        
        { 
            label: 'Tracking', 
            route: '/tracking', 
            iconClasses: 'fa fa-map-marker', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'Cots Activos', 
            route: '/cots', 
            iconClasses: 'fa fa-users', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
    ]
};